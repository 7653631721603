@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");
$mainFontFamily: "Lato", sans-serif;

@import url("https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
$secondaryFontFamily: "Josefin Slab", serif;

$primaryColor: #57514c;
$secondaryColor: #948f8d;
