@import "../../_StyleVariables";

.workPage {
  font-family: $secondaryFontFamily;
  .workWelcome {
    margin: 8rem 0 2rem 0;
    color: $primaryColor;
    font-weight: 300;
    font-size: 4rem;
    text-align: center;
  }
  .workAbout {
    color: $secondaryColor;
    font-size: 1rem;
    text-align: center;
    font-family: $mainFontFamily;
  }
  .tags {
    margin-top: 4rem;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;

    button {
      margin: 0.2rem 0.1rem;
      font-family: $mainFontFamily;
      color: $primaryColor;
      border: none;
      background-color: transparent !important;
      font-size: 1rem;
      font-weight: 300;

      &:active {
        background-color: none;
      }

      &:hover {
        background-color: none;
        font-weight: 500;
        color: #7e5a6a;
      }

      &:disabled {
        color: #7e5a6a;
        text-decoration: underline;
        font-weight: 400;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
