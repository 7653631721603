@import "../../_StyleVariables";

.singleProject {
  margin: 6rem;
  font-family: $mainFontFamily;

  .partOne {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
  h1 {
    margin-bottom:.8rem;
  }
  h4 {
    color: $primaryColor;
    margin: 0;
  }
  .partOneText {
    width: 50%;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
  }

  .mainImg {
    width: 40rem;
    height: 25rem;
    object-fit: cover;
    border: 6px outset rgba(74, 71, 69);
    border-radius: 9px;
    flex-shrink: 1;
  }

  .workWelcome {
    color: $primaryColor;
    font-weight: 300;
    font-size: 4rem;
  }
  .workAbout {
    color: $secondaryColor;
    font-size: 1rem;

    font-family: $mainFontFamily;
  }

  .outerLinks {
    width: 6rem;
    display: flex;
    justify-content: space-between;
    svg {
      width: 2rem;
      height: 2rem;
      fill: $primaryColor;
      path {
        fill: $primaryColor;
      }
    }
  }

  .technologies {
    font-size: 1rem;
    font-weight: 300;

    button {
      font-family: $mainFontFamily;
      color: $primaryColor;
      border: none;
      background-color: transparent !important;
      padding-left: 0 !important;
      padding-right: 1.5rem;

      &:active {
        background-color: none;
      }

      &:hover {
        background-color: none;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .singleProject {
    margin: 2rem;

    .partOne {
      justify-content: center;
    }

    .partOneText {
      width: 90%;
      margin-left: 0;
      text-align: center;
    }
    .mainImg {
      width: 30rem;
      height: 18rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .singleProject {
    .mainImg {
      width: 15rem;
      height: 9rem;
    }

    .outerLinks {
      align-self: center;
      svg {
        width: 1.5rem;
      }
    }
  }
}
