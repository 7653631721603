@import "../../_StyleVariables";

.projectsList {
  color: $primaryColor;

  a {
    text-decoration: none;
    color: $primaryColor;

    &:active,
    &:visited {
      color: inherit;
    }

    &:focus {
      outline-offset: none;
    }
  }

  .project {
    margin: 10rem 2rem;

    .alignLeft {
      // height: 30rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      align-content: center;
      flex-wrap: wrap;
    }
    .alignRight {
      // height: 30rem;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .projectDescription {
      width: 40rem;
      height: auto;
      text-align: justify;
      display: flex;
      flex-direction: column;
      .text {
        padding-top: 4rem;
      }
      p {
        font-size: 1.2rem;
        font-family: $mainFontFamily;
        color: $secondaryColor;
      }

      h2 {
        font-size: 5rem;
        margin: 0;
      }
      .outerLinks {
        width: 6rem;
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
        svg {
          width: 2rem;
          height: 2rem;
          fill: $primaryColor;
          path {
            fill: $primaryColor;
          }
        }
      }
    }
    .card {
      width: 40rem;
      height: 25rem;
      opacity: 0.8;
      object-fit: cover;
      z-index: 1;
      will-change: transform;
      box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
      transition: box-shadow 0.5s;

      &:hover {
        opacity: 1;
        box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .projectsList {
    .project {
      margin: 4rem 2rem;
      height: 100%;
      justify-content: space-evenly;
      align-items: space-evenly;
      align-content: space-evenly;

      .projectDescription {
        width: 30rem;
        padding: 0;
        // margin: 2rem;
        .text {
          padding: 0;
        }

        p {
          font-size: 1rem;
        }

        h2 {
          text-align: center;
          font-size: 2rem;
        }
        .outerLinks {
          align-self: center;
        }
      }
      .card {
        width: 30rem;
        height: 18rem;
        margin: 0;
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .projectsList {
    .project {
      // margin: 4rem;
      .projectDescription {
        .text {
          padding: 0;
        }
        // width: 20rem;
        .outerLinks {
          svg {
            width: 1.5rem;
          }
        }
      }

      .card {
        width: 20rem;
        height: 12rem;
      }
    }
  }
}
