@import "../../_StyleVariables";

.cursor {
  width: 12px;
  height: 12px;
  border: 1px solid $primaryColor;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999;
  mix-blend-mode: difference;
  transition: all 150ms ease;
}

.viewCursor {
  width: 10rem;
  height: 10rem;
  border: 1px solid $primaryColor;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999;
  mix-blend-mode: difference;
  transition: all 150ms ease;

  &:before {
    content: "view case study";
    color: $primaryColor;
    z-index: 999;
    position: fixed;
    top: 4rem;
    left: 1.3rem;
    transition: all 150ms ease;
  }
}

@include media-breakpoint-down(md) {
  .cursor {
    display: none;
  }

  .viewCursor {
    display: none;
  }
}
