@import "../../_StyleVariables";

.description {
  margin: 4rem 6rem;
  height: 92rem;
  display: flex;
  flex-direction: column;

  .butterfly1 {
    position: absolute;
    z-index: 1;
    width: 5rem;
    margin-left: 22rem;
    margin-top: 8rem;
    animation: move 3s ease-in-out infinite;

    @keyframes move {
      0% {
        transform: translate3d(2px, 2px, 0px);
      }

      25% {
        transform: translate3d(4px, 4px, 0px);
      }

      50% {
        transform: translate3d(6px, 6px, 0px);
      }
      75% {
        transform: translate3d(4px, 4px, 0px);
      }
      100% {
        transform: translate3d(2px, 2px, 0px);
      }
    }
  }

  .aboutMe {
    align-self: flex-start;
    // font-family: $mainFontFamily;
    color: $secondaryColor;
    font-size: 1.5rem;
    margin: 0rem;

    span {
      color: #d09dae;
      font-weight: bold;
    }
  }

  h1 {
    color: $secondaryColor;
    font-weight: 300;
    font-size: 12rem;
    align-self: center;
    margin: 0;
  }

  h2 {
    color: $primaryColor;
    font-weight: 300;
    font-style: italic;
    font-size: 12rem;
    align-self: flex-start;
    margin: 6rem 0;
  }

  .hiba {
    width: 60rem;
    align-self: center;
    opacity: 0.6;

    &:hover {
      opacity: 0.8;
    }
  }

  .hibaAnimation {
    width: 40rem;
    align-self: center;
    opacity: 0.6;
    animation: size 2s ease;

    @keyframes size {
      0% {
        width: 60rem;
      }

      25% {
        width: 55rem;
      }

      50% {
        width: 50rem;
      }
      75% {
        width: 45rem;
      }
      100% {
        width: 40rem;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }

  .introduction {
    font-family: $mainFontFamily;
    color: $secondaryColor;
    align-self: flex-end;
    width: 32rem;
    position: absolute;
    right: 3rem;
    margin-right: 4rem;
    top: 44rem;
    text-align: justify;
    text-transform: uppercase;

    span {
      font-style: italic;
      text-transform: none;
    }

    a {
      color: #694e5a;
    }

    .studies {
      color: $primaryColor;
      font-weight: 300;
      text-transform: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .description {
    width: 100%;
    margin: 4rem 0;
    justify-content: center;
    text-align: center;
    height: auto;

    .aboutMe {
      font-size: 1.1rem;
      align-self: center;
    }

    h1 {
      font-size: 5rem;
      margin: 1rem 0;
    }

    .butterfly2 {
      right: 18rem;
      top: 1.5rem;
    }

    .butterfly1 {
      margin-left: 30rem;
      margin-top: -14rem;
    }

    .developer {
      margin: 1rem 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;

      h2 {
        font-size: 5rem;
        margin: 0;
      }
    }

    .hiba,
    .hibaAnimation {
      width: 30rem;
      animation: none;
      opacity: 0.8;
    }

    .introduction {
      width: 30rem;
      position: relative;
      align-self: center;
      top: 0;
      margin: 0;
      right: 0;
    }

    .scroll {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .description {
    margin: 2rem 0;
    .aboutMe {
      font-size: 1rem;
    }

    h1 {
      font-size: 3rem;
    }

    .butterfly1 {
      margin-left: 16rem;
      margin-top: -16rem;
    }

    .developer {
      h2 {
        font-size: 3rem;
      }
    }

    .hiba,
    .hibaAnimation {
      width: 20rem;
    }

    .introduction {
      width: 20rem;
    }
  }
}
